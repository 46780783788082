@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 0.5s infinite;
  margin-bottom: 4px;
}

.waiting-list-form {
  height: 100dvh;
  max-width: 80%;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: baseline;
  align-items: baseline;
}
.button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: none;
  font-family: Poppins, sans-serif;
  line-height: 1.75;
  min-width: 64px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(21, 21, 21);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  padding: 8px;
  flex: 1 1 0%;
  font-size: 14px;
  font-weight: 500;
}

.gift-logo {
  width: 1.875rem;
  height: 1.875rem;
}
.chat-start .chat-bubble:before{
  display: none;
}
.stat-problem {
  white-space: normal;
  font-size: medium;
}